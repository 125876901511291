// en, de, fr, pt
export default {
  en: {
    // Home page
    Home: 'Home',
    GreetingsTitle: 'Welcome',
    GreetingsSubtitle: 'Where will you tell your story ?',
    SpecificLocation: 'In a specific location',
    SpecificLocationSubtitle: 'Users can view the same content in a specific location.',
    SeveralLocations: 'In several locations',
    SeveralLocationsSubtitle: 'Users can view the same or unique content in many different locations.',
    AnyLocation: 'In any location ',
    AnyLocationSubtitle: 'Users can view the same or unique content wherever they are.',
    Create: 'Create',
    ComingSoon: 'Coming Soon',
    // My Spaces
    MySpaceNoLocation: 'Create your first space and start placing objects by clicking on "New Space".',
    AddNew: 'New Space',
    Refresh: 'Refresh',
    Name: 'Name',
    Views: 'Views',
    Visibility: 'Visibility',
    Range: 'Range',
    Action: 'Action',
    Of: 'of',
    MySpaceDeletionTitle: 'Permanently delete this Space?',
    MySpaceDeletionDescription: 'Note that objects used in this Space will remain in your library.',
    // Analytics page
    MostViewed: 'Most Viewed Experiences',
    LeastViewed: 'Least Viewed Experiences',
    ActiveLocation: 'Active locations',
    // My objects page
    AddObject: 'Add object',
    Type: 'Type',
    Usedin: 'Used in',
    Inactive: 'Inactive',
    Active: 'Active',
    ObjectRemoved: 'Object removed',
    // Create and Place form Wizard
    WhereWhen: 'Where & When',
    What: 'What',
    WhatDetails: 'What (Details)',
    How: 'How',
    When: 'When',
    CreateAndPlaceWorkflowWhereTitle: 'Where would you like to publish content?',
    CreateAndPlaceWorkflowWhereRadius: 'Within what radius should your content be activated? (in meters)',
    CreateAndPlaceWorkflowWhereError: 'You must choose a location on the map first',
    // Hoverpack selection
    HoverpackSelectionTitle: 'What type of content do you want to place?',
    HoverpackSelectionImageTitle: 'Image',
    HoverpackSelectionImageDescription: 'From a PNG or JPEG',
    HoverpackSelectionHologramTitle: 'Green-screen',
    HoverpackSelectionHologramDescription: 'From an MP4 file',
    HoverpackSelectionVideoTitle: 'Video',
    HoverpackSelectionVideoDescription: 'From an MP4 file',
    HoverpackSelectionAudioSourceTitle: 'Audio',
    HoverpackSelectionAudioSourceDescription: 'From an MP3 file',
    HoverpackSelectionTextTitle: 'Text',
    HoverpackSelectionTextDescription: 'From typed-in text',
    HoverpackSelectionImmersiveSphereTitle: 'Immersive Sphere',
    HoverpackSelectionImmersiveSphereDescription: 'From a 360 PNG or JPEG file',
    HoverpackSelectionPointCloudTitle: 'Point Cloud',
    HoverpackSelectionPointCloudDescription: 'From a ply file',
    HoverpackSelectionAssetBundlesTitle: '3D Object',
    HoverpackSelectionAssetBundlesDescription: 'From an Unity AssetBundle',
    HoverpackSelection3DTitle: '3D Object',
    HoverpackSelection3DDescription: 'From a GLB file',
    HoverpackSelectionPortalTitle: 'Portal',
    HoverpackSelectionPortalDescription: 'From a 360 PNG, JPEG or MP4 file',
    HoverpackSelectionCTATitle: 'Button',
    HoverpackSelectionCTADescription: 'From a URL',
    HoverpackAdd: 'Add',
    HoverpackAddPaid: "Upgrade to use Unity",
    HoverpackExisting: 'Select from your library',
    AnchorExisting: 'Select from your existing tracked image',
    Height: 'Height',
    HoverpackModel: 'Model',
    // Objects:
    HoverpackImageTitle: 'Enter your image details',
    HoverpackHologramTitle: 'Enter your video details',
    HoverpackVideoTitle: 'Enter your video details',
    HoverpackAudioTitle: 'Enter your audio details',
    HoverpackModelTitle: 'Enter your model details',
    HoverpackButtonTitle: 'Enter your button details',
    HoverpackTextTitle: 'Enter your text details',
    HoverpackPortalTitle: 'Enter your 360 image or 360 video details',
    HoverpackImmersiveSphereTitle: 'Enter your Immersive Sphere details',
    HoverpackPointCloudTitle: 'Enter your point cloud details',
    HoverpackUnityAssetBundleTitle: 'Enter your Unity asset details',
    // Edit space page
    SpaceSettings: 'Settings',
    QrCode: 'Scan this code or use the link to access this space from anywhere',
    QrCodeCopy: 'QrCode link copied successfully.',
    QrTestCodeCopy: 'QrCode link copied successfully. This code is for testing only. Do not share.',
    GridDescription: 'Drag objects into position. One square is equal to 1 meter (3.2 feets)',
    DownloadTitle: 'How long will it take viewers to load this Space?',
    SizeContent: 'Total size of your content is',
    DownloadWifi: 'Wifi download time',
    Download4G: '4G download time ',
    Download3G: '3G download time',
    Download2G: '2G download time',
    DownloadInst: 'instantaneous',
    AddObjectButton: 'Add a new object in this space',
    ResetButton: 'Reset',
    SaveButton: 'Save',
    DeleteNodeTitle: 'Remove object from this Space?',
    DeleteNodeDescription: 'Note that this object will remain in your library',
    DeleteHobjectPermanentlyTitle: 'Permanently delete from library?',
    DeleteHobjectPermanentlyDescription: 'This object and all references to it will be removed from all spaces.',
    Accept: 'Accept',
    Cancel: 'Cancel',
    Delete: 'Delete',
    Remove: 'Remove',
    Yes: 'Yes',
    No: 'No',
    Elevation: 'Elevation relative to the anchor (in meters)',
    Anchor: 'Anchor',
    AnchorEyes: 'floating at eyes level',
    AnchorFloor: 'at floor level, on a horizontal surface',
    AnchorTable: 'at table-top level, on a horizontal surface',
    // SpaceSettings Page
    EditPageLocationName: 'Space display name',
    EditPageLocationmapDescription: 'Drag and drop the circle to move the activation zone',
    EditPageLocationBackButton: 'Back',
    // Image Object
    HoverpackImageDrag: 'Upload or drag your image here.',
    HoverpackHeight: 'Height of the object',
    HoverpackUnit: 'Unit',
    HoverpackRotation: 'Always rotate the object to face the user',
    HoverpackFrame: 'Virtual Frame',
    HoverpackImageFileSizeError: 'Compress file or upgrade to upload larger files (up to 200MB)',
    // Video Object
    HoverpackVideoDrag: 'Upload or drag your video here.',
    HoverpackVideoInfo: 'The video asset needs to be an mp4 video.',
    // Audio Object
    HoverpackAudioDrag: 'Upload or drag your audio file here.',
    HoverpackAudioInfo: 'The audio asset needs to be an mp3 file.',
    // GLTF Object
    HoverpackGltfDrag: 'Upload or drag your GLB or GLTF here',
    HoverpackGltfError: 'The GLB url is not valid',
    HoverpackGltfAutoResize: 'Height of your model',
    FindAndAdjustLowestPivotPoint: 'Adjust pivot-point based on the lowest polygon of the model',
    // UAB
    HoverpackUabDrag: 'Upload or drag your ZIP archive',
    HoverpackUabError: 'The Asset Bundle url is not valid',
    // Immersive Sphere Object
    HoverpackImmersiveSphereDrag: 'Upload or drag your 360 image here.',
    HoverpackImmersiveSphereRadius: 'Sphere radius',
    HoverpackImmersiveSphereEdgeDistance: 'Degree of immersive',
    HoverpackImmersiveSphereEdgeWidth: 'Edge width',
    HoverpackImmersiveSphereCameraElevation: '360 Camera ground elevation',
    HoverpackImmersiveSphereGrounded: 'Grounded',
    HoverpackImmersiveSphereFileSizeError: 'Compress file or upgrade to upload larger files (up to 200MB)',
    // PLY Point Cloud
    HoverpackPlyDrag: 'Upload or drag your PLY file here',
    HoverpackPlyError: 'The PLY url is not valid',
    HoverpackPlyAutoResize: 'Height of your model',
    FindAndAdjustPointCloudPivotPoint: 'Set pivot-point at the base of the point cloud',
    // CTA
    HoverpackCTALink: 'Destination Link (https required)',
    HoverpackCTAError: 'You must provide a valid a destination link',
    HoverpackCTAValid: 'The url is valid',
    HoverpackCTANotValid: 'The url is not valid',
    // Profile Page
    ProfileChannelPicture: 'Change Channel Picture',
    ProfileChannelPictureSuccess: 'Success',
    ProfileChannelPictureSuccessText: 'Channel picture updated',
    ProfileChannelPictureDelete: 'Photo has been deleted',
    ProfileUpdateFailTitle: '',
    ProfileUpdateFail: 'Profile could not be updated',
    ProfileUpdateTitle: '',
    ProfileUpdate: 'Profile updated',
    ProfileAllow: 'Allowed JPG or PNG.',
    ProfileVerified: 'Your account is not <b>verified</b>. Check your emails and click on the confirmation link.',
    ProfileConfirmTitle: 'Warning',
    ProfileConfirm: 'Changing your channel name will break all existing artwork links. Do you want to proceed ?',
    ProfileChannelName: 'Channel Name',
    ProfileSave: 'Save',
    ProFileReset: 'Cancel',
    // Menu
    Faq: 'FAQ',
    Analytics: 'Analytics',
    MySpaces: 'My Spaces',
    ObjectsLibrary: 'Objects Library',
    ReferenceImageLibrary: 'Anchors Library',
    QuickStart: 'Quick Start',
    Dashboard: 'Dashboard',
    eCommerce: 'eCommerce',
    Apps: 'Apps',
    Todo: 'Todo',
    Chat: 'Chat',
    Email: 'Email',
    Calendar: 'Calendar',
    FullCalendar: 'Full Calendar',
    SimpleCalendar: 'Simple Calendar',
    Shop: 'Shop',
    ItemDetails: 'Item Details',
    Cart: 'Cart',
    WishList: 'Wish List',
    Checkout: 'Checkout',
    UI: 'UI',
    DataList: 'Data List',
    ListView: 'List View',
    ThumbView: 'Thumb View',
    Grid: 'Grid',
    Vuexy: 'Vuexy',
    Vuesax: 'Vuesax',
    Tailwind: 'Tailwind',
    Colors: 'Colors',
    Card: 'Card',
    Basic: 'Basic',
    Statistics: 'Statistics',
    CardActions: 'Card Actions',
    CardColors: 'Card Colors',
    Table: 'Table',
    agGridTable: 'agGrid Table',
    Components: 'Components',
    Alert: 'Alert',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Breadcrumb: 'Breadcrumb',
    Button: 'Button',
    ButtonGroup: 'Button Group',
    Chip: 'Chip',
    Collapse: 'Collapse',
    Dialogs: 'Dialogs',
    Divider: 'Divider',
    DropDown: 'DropDown',
    List: 'List',
    Loading: 'Loading',
    Navbar: 'Navbar',
    Notifications: 'Notifications',
    Pagination: 'Pagination',
    Popup: 'Popup',
    Progress: 'Progress',
    Sidebar: 'Sidebar',
    Slider: 'Slider',
    Tabs: 'Tabs',
    Tooltip: 'Tooltip',
    Upload: 'Upload',
    FormsAndTable: 'Forms & Table',
    FormElements: 'Form Elements',
    Select: 'Select',
    Switch: 'Switch',
    Checkbox: 'Checkbox',
    Radio: 'Radio',
    Input: 'Input',
    NumberInput: 'Number Input',
    Textarea: 'Textarea',
    FormLayouts: 'Form Layouts',
    FormWizard: 'Form Wizard',
    FormValidation: 'Form Validation',
    FormInputGroup: 'Form Input Group',
    Pages: 'Pages',
    Authentication: 'Authentication',
    Login: 'Login',
    Register: 'Register',
    ForgotPassword: 'Forgot Password',
    ResetPassword: 'Reset Password',
    LockScreen: 'Lock Screen',
    Miscellaneous: 'Miscellaneous',
    Error: 'Error',
    404: '404',
    500: '500',
    NotAuthorized: 'Not Authorized',
    Maintenance: 'Maintenance',
    Profile: 'Profile',
    User: 'User',
    View: 'View',
    Edit: 'Edit',
    UserSettings: 'User Settings',
    Invoice: 'Invoice',
    FAQ: 'FAQ',
    Search: 'Search',
    KnowledgeBase: 'Knowledge Base',
    ChartsAndMaps: 'Charts & Maps',
    Charts: 'Charts',
    ApexCharts: 'Apex Charts',
    chartjs: 'chartjs',
    echarts: 'echarts',
    chartist: 'chartist',
    GoogleMap: 'Google Map',
    Extensions: 'Extensions',
    QuillEditor: 'Quill Editor',
    DragAndDrop: 'Drag & Drop',
    Datepicker: 'Datepicker',
    DatetimePicker: 'Datetime Picker',
    AccessControl: 'Access Control',
    I18n: 'I18n',
    Carousel: 'Carousel',
    Clipboard: 'Clipboard',
    ContextMenu: 'Context Menu',
    StarRatings: 'Star Ratings',
    Autocomplete: 'Autocomplete',
    Tree: 'Tree',
    // "Import/Export": 'Import/Export',
    Import: 'Import',
    Export: 'Export',
    ExportSelected: 'Export Selected',
    Others: 'Others',
    MenuLevels: 'Menu Levels',
    MenuLevel2p1: 'Menu Level 2.1',
    MenuLevel2p2: 'Menu Level 2.2',
    MenuLevel3p1: 'Menu Level 3.1',
    MenuLevel3p2: 'Menu Level 3.2',
    DisabledMenu: 'Disabled Menu',
    Support: 'Support',
    Documentation: 'Documentation',
    RaiseSupport: 'Raise Support',
    demoTitle: 'Card Title',
    demoText:
      'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.',
  },
  fr: {
    // Home page
    GreetingsTitle: 'Bienvenue',
    GreetingsSubtitle: 'Où voulez-vous raconter votre histoire ?',
    SpecificLocation: 'Dans un endroit particulier',
    SpecificLocationSubtitle: 'Votre audience peut voir votre contenu dans un endroit donné',
    SeveralLocations: 'Dans plusieurs endroits',
    SeveralLocationsSubtitle: 'Votre audience peut voir votre contenu dans plusieurs endroits',
    AnyLocation: 'Partout',
    AnyLocationSubtitle: 'Votre audience peut voir votre contenu partout dans le monde',
    Create: 'Je commence à créer !',
    ComingSoon: 'Bientôt disponible',
    // My Spaces
    MySpaceNoLocation:
      "On dirait que vous n'avez pas encore créer d'Espace ! Cliquez sur le bouton \"ajouter\" en haut à gauche de l'écran pour le créer.",
    AddNew: 'Ajouter',
    Refresh: 'Rafraichir',
    Name: 'Nom',
    Views: 'Vues',
    Visibility: 'Visibilité',
    Range: 'Zone',
    Action: 'Action',
    Of: 'sur',
    MySpaceDeletionTitle: 'Supprimer cet Espace définitivement?',
    MySpaceDeletionDescription: "Notez que vos objets resteront disponibles dans la bibliothèque d'objets. Continuer?",
    // Analytics
    MostViewed: 'Contenus les plus visionnés',
    LeastViewed: 'Contenus les moins visionnés',
    ActiveLocation: 'Endroits actifs',
    // My objects page
    AddObject: 'Ajouter un object',
    Type: 'Type',
    Status: 'Utiliser',
    Inactive: 'Inactif',
    Active: 'Actif',
    ObjectRemoved: 'Objet supprimé',
    // Create and Place form Wizard
    Where: 'Où',
    What: 'Quoi',
    WhatDetails: 'Quoi (Détails)',
    How: 'Comment',
    When: 'Quand',
    CreateAndPlaceWorkflowWhereTitle: 'Où voulez-vous publier votre contenu ?',
    CreateAndPlaceWorkflowWhereRadius: 'Quel est le rayon (en mètres) de visibilité de votre contenu ?',
    CreateAndPlaceWorkflowWhereError: "Vous devez d'abord sélectionner un endroit sur la carte",
    // Hoverpack selection
    HoverpackSelectionTitle: 'Quel type de contenu voulez-vous publier ?',
    HoverpackSelectionImageTitle: 'Image',
    HoverpackSelectionImageDescription: "A partir d'un fichier PNG ou JPEG",
    HoverpackSelectionHologramTitle: 'Hologramme',
    HoverpackSelectionHologramDescription: "A partir d'une video fond-vert",
    HoverpackSelectionVideoTitle: 'Video',
    HoverpackSelectionVideoDescription: "A partir d'un fichier mp4",
    HoverpackSelectionAudioSourceTitle: 'Audio',
    HoverpackSelectionAudioSourceDescription: "A partir d'un fichier mp3",
    HoverpackSelectionAssetBundlesTitle: 'Modèle 3D',
    HoverpackSelectionAssetBundlesDescription: "à partir d'un Asset Bundle Unity",
    HoverpackSelectionTextTitle: 'Texte',
    HoverpackSelectionTextDescription: "A partir d'une entrée clavier",
    HoverpackSelection3DTitle: 'Modèle 3D',
    HoverpackSelection3DDescription: "A partir d'un fichier",
    HoverpackSelectionCTATitle: 'Bouton 3D',
    HoverpackSelectionCTADescription: "A partir d'une URL",
    HoverpackAdd: 'Ajouter',
    HoverpackAddPaid: "Upgrade necessaire pour utiliser Unity",
    HoverpackExisting: 'Choisissez dans la liste de vos objects existants',
    AnchorExisting: 'Choisissez dans la liste de vos images de référence existantes',
    Height: 'Hauteur',
    HoverpackModel: 'Modèle',
    // Objects:
    HoverpackImageTitle: "Entrez les caractéristiques de l'image",
    HoverpackHologramTitle: 'Entrez les caractéristiques de la vidéo',
    HoverpackAudioTitle: 'Entrez les caractéristiques audio',
    HoverpackVideoTitle: 'Entrez les caractéristiques de la vidéo',
    HoverpackModelTitle: 'Entrez les caractéristiques du modèle',
    HoverpackButtonTitle: 'Entrez les caractéristiques du button',
    HoverpackTextTitle: 'Entrez votre texte',
    // Edit space page
    SpaceSettings: 'Modifier le placement',
    QrCode: 'Scannez le code avec votre téléphone pour tester ou copier le lien.',
    QrCodeCopy: 'Le QrCode à été copié dans votre presse papier',
    GridDescription: 'Glissez-déposez les objects. Un carré est équivalent à 1 mètre.',
    DownloadTitle: "Combien de temps va prendre mon téléphone pour charger l'experience ?",
    SizeContent: 'La taille total de votre contenu est de ',
    DownloadWifi: 'Temps de téléchargement en Wifi',
    Download4G: 'Temps de téléchargement en 4G',
    Download3G: 'Temps de téléchargement en 3G',
    Download2G: 'Temps de téléchargement en 2G',
    DownloadInst: 'immédiat',
    AddObjectButton: 'Ajouter un nouvel object à cet endroit',
    ResetButton: 'Ré-initialiser',
    SaveButton: 'Sauvegarder',
    DeleteNodeTitle: "Retirer l'object de cet Espace?",
    DeleteNodeDescription: "Notez que l'object restera disponible dans votre librarie",
    DeleteHobjectPermanentlyTitle: 'Supprimer de la librairie?',
    DeleteHobjectPermanentlyDescription: 'Cet object et toutes references seront définitivement supprimés.',
    Accept: 'Accepter',
    Cancel: 'Annuler',
    Delete: 'Supprimer',
    Remove: 'Retirer',
    Yes: 'Oui',
    No: 'Non',
    Elevation: "hauteur de l'object par rapport au point d'attachement",
    Anchor: "Point d'attachement",
    AnchorEyes: 'Hauteur des yeux',
    AnchorFloor: 'au niveau sur sol, sur une surface horizontale',
    AnchorTable: "au niveau d'une table ",
    // Advanced
    EditSpaceScale: '',
    EditSpaceLeftRight: '',
    EditSpaceForward: '',
    // SpaceSettings Page
    EditPageLocationName: 'Nom personnalisé',
    EditPageLocationmapDescription: 'Glissez-déplacez le cercle pour déplacer la localisation',
    EditPageLocationBackButton: 'Retour',
    // Image Object
    HoverpackImageDrag: 'Choisir une image...',
    HoverpackHeight: "Hauteur de l'object",
    HoverpackUnit: 'Unité',
    HoverpackRotation: "Faire pivoter l'object pour qu'il soit toujours en face de l'utilisateur",
    HoverpackFrame: 'Ajouter un cadre virtuel',
    // Video Object
    HoverpackVideoDrag: 'Choisir une vidéo...',
    HoverpackVideoInfo: 'La vidéo doit être au format MP4, assurez vous que le fichier soit inférieur à 20Mb',
    // AudioSource Object
    HoverpackAudioDrag: 'Choisir un fichier audio...',
    HoverpackAudioInfo: 'Le fichier doit être au format MP3, assurez vous que le fichier soit inférieur à 20Mb',
    // GLTF Object
    HoverpackGltfDrag: 'Choisir un fichier GLTF ou GLB',
    HoverpackGltfError: "Vous n'avez pas téléverser le fichier",
    HoverpackGltfAutoResize: 'Taille de votre model',
    FindAndAdjustLowestPivotPoint: 'Ajuste le point de pivot en fonction du mesh le plus bas',
    // UAB
    HoverpackUabDrag: 'Choisir un Asset Bundle Unity...',
    HoverpackUabError: "L'Asset Bundle est invalide",
    HoverpackCTALink: 'Destination du lien (https obligatoire)',
    HoverpackCTAError: 'Le lien de destination doit être valide',
    HoverpackCTAValid: 'Le lien est valide',
    HoverpackCTANotValid: "Le lien n'est pas valide",
    // Profile Page
    ProfileChannelPicture: 'Modifier mon image',
    ProfileChannelPictureSuccess: 'Succès',
    ProfileChannelPictureSuccessText: 'Image sauvegardé',
    ProfileChannelPictureDelete: 'Image supprimé',
    ProfileUpdateFailTitle: '',
    ProfileUpdateFail: "Impossible de changer l'image",
    ProfileUpdateTitle: '',
    ProfileUpdate: 'Profile sauvegardé',
    ProfileAllow: 'Fichiers acceptés JPEG, PNG ou GIF',
    ProfileVerified: "Votre compte n'est pas vérifié. Vérifiez vos e-mails et cliquez sur le lien de confirmation",
    ProfileConfirmTitle: 'Attention',
    ProfileConfirm:
      'Changer votre nom de canal va changer les liens de tout vos liens existant ? Êtes vous sûr de vouloir faire celà ? ',
    ProfileChannelName: 'Nom de canal',
    ProfileSave: 'Sauvegarder',
    ProFileReset: 'Annuler',
    // Menu
    Home: 'Accueil',
    MySpaces: 'Mes espaces',
    Analytics: 'Mes statistiques',
    ObjectsLibrary: "Bibliothèque d'objets",
    ReferenceImageLibrary: "Bibliothèque d'ancres",
    MyGallery: 'Ma Gallerie',
    Faq: 'Documentation',
    Dashboard: 'Tableau de bord',
    eCommerce: 'commerce électronique',
    Apps: 'Applications',
    Todo: 'Faire',
    Chat: 'Bavarder',
    Email: 'Email',
    Calendar: 'Calendrier',
    FullCalendar: 'Calendrier complet',
    SimpleCalendar: 'Calendrier simple',
    Shop: 'Boutique',
    ItemDetails: "Détails de l'article",
    Cart: 'Chariot',
    WishList: 'Liste de souhaits',
    Checkout: 'Check-out',
    UI: 'UI',
    DataList: 'Liste de données',
    ListView: 'Voir la liste',
    ThumbView: 'Thumb View',
    Grid: 'la grille',
    Vuexy: 'Vuexy',
    Vuesax: 'Vuesax',
    Tailwind: 'Tailwind',
    Colors: 'Couleurs',
    Card: 'Carte',
    Basic: 'De base',
    Statistics: 'Statistiques',
    CardActions: 'Actions de carte',
    CardColors: 'Couleurs de la carte',
    Table: 'Table',
    agGridTable: 'tableau agGrid',
    Components: 'Composants',
    Alert: 'Alerte',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Breadcrumb: 'Breadcrumb',
    Button: 'Bouton',
    ButtonGroup: 'Groupe de boutons',
    Chip: 'Puce',
    Collapse: 'Effondrer',
    Dialogs: 'Dialogues',
    Divider: 'Diviseur',
    DropDown: 'Menu déroulant',
    List: 'liste',
    Loading: 'Chargement',
    Navbar: 'Barre de navigation',
    Notifications: 'Les notifications',
    Pagination: 'Pagination',
    Popup: 'Apparaitre',
    Progress: 'Le progrès',
    Sidebar: 'Barre latérale',
    Slider: 'Curseur',
    Tabs: 'Onglets',
    Tooltip: 'Info-bulle',
    Upload: 'Télécharger',
    FormsAndTable: 'Formulaires et tableau',
    FormElements: 'Éléments de formulaire',
    Select: 'Sélectionner',
    Switch: 'Commutateur',
    Checkbox: 'Case à cocher',
    Radio: 'Radio',
    Input: 'Contribution',
    NumberInput: "Nombre d'entrée",
    Textarea: 'Textarea',
    FormLayouts: 'Dispositions de formulaire',
    FormWizard: 'Assistant de formulaire',
    FormValidation: 'Validation du formulaire',
    FormInputGroup: 'Groupe de saisie de formulaire',
    Pages: 'Pages',
    Authentication: 'Authentification',
    Login: "S'identifier",
    Register: 'registre',
    ForgotPassword: 'Mot de passe oublié',
    ResetPassword: 'réinitialiser le mot de passe',
    LockScreen: 'Écran verrouillé',
    Miscellaneous: 'Divers',
    Error: 'Erreur',
    404: '404',
    500: '500',
    NotAuthorized: 'Pas autorisé',
    Maintenance: 'Entretien',
    Profile: 'Profil',
    User: 'Utilisateur',
    View: 'Vue',
    Edit: 'Modifier',
    UserSettings: 'Paramètres utilisateur',
    Invoice: "Facture d'achat",
    FAQ: 'FAQ',
    Search: 'Chercher',
    KnowledgeBase: 'Base de connaissances',
    ChartsAndMaps: 'Graphiques Et Cartes',
    Charts: 'Graphiques',
    ApexCharts: 'Apex Charts',
    chartjs: 'chartjs',
    echarts: 'echarts',
    chartist: 'chartist',
    GoogleMap: 'Google Carte',
    Extensions: 'Extensions',
    QuillEditor: 'Éditeur de piquants',
    DragAndDrop: 'Drag & Drop',
    Datepicker: 'Sélecteur de date',
    DatetimePicker: 'Sélecteur de date / heure',
    AccessControl: "Contrôle d'accès",
    I18n: 'I18n',
    Carousel: 'Carrousel',
    Clipboard: 'Presse-papiers',
    ContextMenu: 'Menu contextuel',
    StarRatings: 'Classement par étoiles',
    Autocomplete: 'Terminé automatiquement',
    Tree: 'Arbre',
    // "Import/Export": 'Importation/Exportation',
    Import: 'Importation',
    Export: 'Exportation',
    ExportSelected: 'Exporter sélectionné',
    Others: 'Autre',
    MenuLevels: 'Niveaux de menu',
    MenuLevel2p1: 'Niveau de menu 2.1',
    MenuLevel2p2: 'Niveau de menu 2.2',
    MenuLevel3p1: 'Niveau de menu 3.1',
    MenuLevel3p2: 'Niveau de menu 3.2',
    DisabledMenu: 'Menu désactivé',
    Support: 'Soutien',
    Documentation: 'Documentation',
    RaiseSupport: 'Augmenter le soutien',
    demoTitle: 'Titre de la carte',
    demoText:
      "Le gâteau au sésame s'enclenche au petit pain au pain d'épices danois J'adore le pain d'épices. Tarte aux pommes et jujubes chupa chups muffin halvah sucette. Tiramisu gâteau au chocolat gâteau d'avoine tiramisu prune sucre. Donut tarte sucrée gâteau à l'avoine dragée gouttes de fruits gâteau au citron en barbe à papa.",
  },
  de: {
    ObjectsLibrary: 'Meine Objekte',
    Dashboard: 'Instrumententafel',
    eCommerce: 'eCommerce',
    Apps: 'Apps',
    Todo: 'Machen',
    Chat: 'Plaudern',
    Email: 'Email',
    Calendar: 'Kalender',
    FullCalendar: 'Calendrier completVollständiger Kalender',
    SimpleCalendar: 'Einfacher Kalender',
    Shop: 'Geschäft',
    ItemDetails: 'Artikeldetails',
    Cart: 'Wagen',
    WishList: 'Wunschzettel',
    Checkout: 'Auschecken',
    UI: 'UI',
    DataList: 'Datenliste',
    ListView: 'Listenansicht',
    ThumbView: 'Daumenansicht',
    Grid: 'Gitter',
    Vuexy: 'Vuexy',
    Vuesax: 'Vuesax',
    Tailwind: 'Tailwind',
    Colors: 'Farben',
    Card: 'Karte',
    Basic: 'Basic',
    Statistics: 'Statistiken',
    CardActions: 'Kartenaktionen',
    CardColors: 'Kartenfarben',
    Table: 'Tabelle',
    agGridTable: 'agGrid-Tabelle',
    Components: 'Komponenten',
    Alert: 'Warnen',
    Avatar: 'Benutzerbild',
    Badge: 'Abzeichen',
    Breadcrumb: 'Breadcrumb',
    Button: 'Taste',
    ButtonGroup: 'Schaltflächengruppe',
    Chip: 'Chip',
    Collapse: 'Zusammenbruch',
    Dialogs: 'Dialoge',
    Divider: 'Teiler',
    DropDown: 'Dropdown-Liste',
    List: 'Liste',
    Loading: 'Wird geladen',
    Navbar: 'Navbar',
    Notifications: 'Benachrichtigungen',
    Pagination: 'Seitennummerierung',
    Popup: 'Pop-up',
    Progress: 'Fortschritt',
    Sidebar: 'Seitenleiste',
    Slider: 'Schieberegler',
    Tabs: 'Tabs',
    Tooltip: 'QuickInfo',
    Upload: 'Hochladen',
    FormsAndTable: 'Formulare und Tabelle',
    FormElements: 'Formularelemente',
    Select: 'Wählen',
    Switch: 'Schalter',
    Checkbox: 'Ankreuzfeld',
    Radio: 'Radio',
    Input: 'Eingang',
    NumberInput: 'Nummer eingeben',
    Textarea: 'Textbereich',
    FormLayouts: 'Formularlayouts',
    FormWizard: 'Formzauberer',
    FormValidation: 'Formularvalidierung',
    FormInputGroup: 'Formulareingabegruppe',
    Pages: 'Seiten',
    Authentication: 'Authentifizierung',
    Login: 'Anmeldung',
    Register: 'Registrieren',
    ForgotPassword: 'Passwort vergessen',
    ResetPassword: 'Passwort zurücksetzen',
    LockScreen: 'Bildschirm sperren',
    Miscellaneous: 'Verschiedenes',
    ComingSoon: 'Demnächst',
    Error: 'Error',
    404: '404',
    500: '500',
    NotAuthorized: 'Nicht berechtigt',
    Maintenance: 'Instandhaltung',
    Profile: 'Profil',
    User: 'Benutzer',
    View: 'Aussicht',
    Edit: 'Bearbeiten',
    UserSettings: 'Benutzereinstellungen',
    Invoice: 'Rechnung',
    FAQ: 'FAQ',
    Search: 'Suche',
    KnowledgeBase: 'Wissensbasis',
    ChartsAndMaps: 'Diagramme und Karten',
    Charts: 'Diagramme',
    ApexCharts: 'Apex Charts',
    chartjs: 'chartjs',
    echarts: 'echarts',
    chartist: 'chartist',
    GoogleMap: 'Google Karte',
    Extensions: 'Erweiterungen',
    QuillEditor: 'Quill-Editor',
    DragAndDrop: 'Ziehen und loslassen',
    Datepicker: 'Datumsauswahl',
    DatetimePicker: 'Datum / Uhrzeit-Auswahl',
    AccessControl: 'Zugangskontrolle',
    I18n: 'I18n',
    Carousel: 'Karussell',
    Clipboard: 'Zwischenablage',
    ContextMenu: 'Kontextmenü',
    StarRatings: 'Sterne Bewertungen',
    Autocomplete: 'Autovervollständigung',
    Tree: 'Baum',
    // "Import/Export": 'Einführen/Export',
    Import: 'Einführen',
    Export: 'Export',
    ExportSelected: 'Auswahl exportieren',
    Others: 'Andere',
    MenuLevels: 'Menüebenen',
    MenuLevel2p1: 'Menüebene 2.1',
    MenuLevel2p2: 'Menüebene 2.2',
    MenuLevel3p1: 'Menüebene 3.1',
    MenuLevel3p2: 'Menüebene 3.2',
    DisabledMenu: 'Deaktiviertes Menü',
    Support: 'Unterstützung',
    Documentation: 'Dokumentation',
    RaiseSupport: 'Unterstützung erheben',
    demoTitle: 'Kartentitel',
    demoText:
      'Kuchen Sesam Schnaps Cupcake Lebkuchen Dänisch Ich liebe Lebkuchen. Apfelkuchen-Torte-Jujuben chupa chups muffin halvah Lutscher. Schokoladenkuchenhaferkuchen Tiramisu-Marzipanzuckerpflaume. Donut süße Kuchen Hafer Kuchen Dragée Obstkuchen Zuckerwatte Zitrone Tropfen.',
  },
  pt: {
    ObjectsLibrary: 'Meus objetos',
    Dashboard: 'painel de controle',
    eCommerce: 'comércio eletrônico',
    Apps: 'APPS',
    Todo: 'Façam',
    Chat: 'Bate-papo',
    Email: 'O email',
    Calendar: 'Calendário',
    FullCalendar: 'Calendário Completo',
    SimpleCalendar: 'Calendário Simples',
    Shop: 'fazer compras',
    ItemDetails: 'Detalhes do item',
    Cart: 'Carrinho',
    WishList: 'Lista de Desejos',
    Checkout: 'Confira',
    UI: 'UI',
    DataList: 'Lista de dados',
    ListView: 'Exibição de lista',
    ThumbView: 'Thumb View',
    Grid: 'Grade',
    Vuexy: 'Vuexy',
    Vuesax: 'Vuesax',
    Tailwind: 'Tailwind',
    Colors: 'Cores',
    Card: 'Cartão',
    Basic: 'Basic',
    Statistics: 'Estatisticas',
    Analytics: 'Analytics',
    CardActions: 'Ações do Cartão',
    CardColors: 'Cores do cartão',
    Table: 'Mesa',
    agGridTable: 'Tabela AgGrid',
    Components: 'Componentes',
    Alert: 'Alerta',
    Avatar: 'Avatar',
    Badge: 'Crachá',
    Breadcrumb: 'Breadcrumb',
    Button: 'Botão',
    ButtonGroup: 'Grupo de botões',
    Chip: 'Lasca',
    Collapse: 'Colapso',
    Dialogs: 'Diálogos',
    Divider: 'Divisor',
    DropDown: 'Suspenso',
    List: 'Lista',
    Loading: 'Carregando',
    Navbar: 'Navbar',
    Notifications: 'Notificações',
    Pagination: 'Paginação',
    Popup: 'Aparecer',
    Progress: 'Progresso',
    Sidebar: 'Barra Lateral',
    Slider: 'Controle Deslizante',
    Tabs: 'Guias',
    Tooltip: 'dica de ferramenta',
    Upload: 'Envio',
    FormsAndTable: 'Formulários e tabela',
    FormElements: 'Elementos do formulário',
    Select: 'Selecione',
    Switch: 'Interruptor',
    Checkbox: 'Caixa de seleção',
    Radio: 'Rádio',
    Input: 'Entrada',
    NumberInput: 'Entrada numérica',
    Textarea: 'Textarea',
    FormLayouts: 'Layouts de formulário',
    FormWizard: 'Assistente de Formulário',
    FormValidation: 'Validação de Formulário',
    FormInputGroup: 'Grupo de entrada de formulário',
    Pages: 'PÁGINAS',
    Authentication: 'Autenticação',
    Login: 'Entrar',
    Register: 'Registo',
    ForgotPassword: 'Esqueceu A Senha',
    ResetPassword: 'Redefinir Senha',
    LockScreen: 'Tela de bloqueio',
    Miscellaneous: 'Diversos',
    ComingSoon: 'Em breve',
    Error: 'Erro',
    404: '404',
    500: '500',
    NotAuthorized: 'Não autorizado',
    Maintenance: 'Manutenção',
    Profile: 'Perfil',
    User: 'Do utilizador',
    View: 'Visão',
    Edit: 'Editar',
    UserSettings: 'Configurações do Usuário',
    Invoice: 'Fatura',
    FAQ: 'Perguntas frequentes',
    Search: 'Procurar',
    KnowledgeBase: 'Base de Conhecimento',
    ChartsAndMaps: 'Gráficos E Mapas',
    Charts: 'Gráficos',
    ApexCharts: 'Apex Charts',
    chartjs: 'chartjs',
    echarts: 'echarts',
    chartist: 'chartist',
    GoogleMap: 'Google Mapa',
    Extensions: 'Extensão',
    QuillEditor: 'Quill-Editor',
    DragAndDrop: 'Arraste e solte',
    Datepicker: 'Datepicker',
    DatetimePicker: 'Seletor de data e hora',
    AccessControl: 'Controle de acesso',
    I18n: 'I18n',
    Carousel: 'Carrossel',
    Clipboard: 'Prancheta',
    ContextMenu: 'Menu contextual',
    StarRatings: 'Classificações por estrelas',
    Autocomplete: 'autocompletar',
    Tree: 'Árvore',
    // "Import/Export": 'Importar/Exportar',
    Import: 'Importar',
    Export: 'Exportar',
    ExportSelected: 'Exportar Selecionado',
    Others: 'Outras',
    MenuLevels: 'Níveis de Menu',
    MenuLevel2p1: 'Nível de menu 2.1',
    MenuLevel2p2: 'Nível de menu 2.2',
    MenuLevel3p1: 'Nível de menu 3.1',
    MenuLevel3p2: 'Nível de menu 3.2',
    DisabledMenu: 'Menu desativado',
    Support: 'Apoio, suporte',
    Documentation: 'Documentação',
    RaiseSupport: 'Levantar Suporte',
    demoTitle: 'Título do cartão',
    demoText:
      'O sésamo do bolo agarra dinamarquês do pão-de-espécie do queque eu amo o pão-de-espécie. Torta de torta de maçã jujuba chupa chups muffin halvah pirulito. Ameixa do açúcar do maçapão do tiramisu do bolo da aveia do bolo de chocolate. Bolo de aveia de torta doce rosquinha dragée fruitcake algodão doce gotas de limão.',
  },
}
